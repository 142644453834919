<template>
  <div>
    <user-list-add-new
      :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
    />
    <user-list-edit
      :is-edit-user-sidebar-active.sync="isEditUserSidebarActive"
    />
    <b-card-text class="text">
      A role provided access to predefined menus and features so that depending
      <br />
      on assigned role an administrator can have access to what he need
    </b-card-text>
    <div>
      <b-row>
        <b-col
          v-for="(role, index) in roleOptions"
          :key="index"
          cols="12"
          md="4"
        >
          <b-card>
            <b-row align-v="center">
              <b-col sm="6">
                <span>Total {{ role.item }} users</span>
              </b-col>
              <b-col sm="6" class="text-right">
                <b-avatar-group size="2em" class="mt-50 avatars">
                  <b-avatar
                    v-b-tooltip.hover
                    class="pull-up"
                    title="Jenny Looper"
                    :src="
                      require('@/assets/images/portrait/small/avatar-s-5.jpg')
                    "
                  />
                  <b-avatar
                    v-b-tooltip.hover
                    class="pull-up"
                    title="Jennifer Lopez"
                    :src="
                      require('@/assets/images/portrait/small/avatar-s-6.jpg')
                    "
                  />
                  <b-avatar
                    v-b-tooltip.hover
                    class="pull-up"
                    title="Arya Stark"
                    :src="
                      require('@/assets/images/portrait/small/avatar-s-7.jpg')
                    "
                  />
                </b-avatar-group>
              </b-col>
              <b-col sm="12" class="mt-1">
                <h3>{{ role.label }}</h3>
              </b-col>
              <b-col sm="6">
                <b-link @click="isEditUserSidebarActive = true">
                  <span class="font-weight-bold">Edit Role</span>
                </b-link>
              </b-col>
              <b-col sm="6" class="text-right">
                <feather-icon icon="CopyIcon" size="20" class="copy" />
              </b-col>
            </b-row>
          </b-card>
        </b-col>
        <b-col>
          <b-card
            class="cursor-pointer"
            @click="isAddNewUserSidebarActive = true"
          >
            <b-row>
              <b-col sm="4">
                <b-img
                  src="@/assets/images/illustration/faq-illustrations.svg"
                  alt="avatar"
                  height="95px"
                />
              </b-col>
              <b-col sm="8" class="text-right">
                <b-button variant="primary" class="pl-50 pr-50">
                  Add New Role
                </b-button>
                <p class="mt-1 mb-0">Add role, if it does not exist</p>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  BCard,
  BCardText,
  BRow,
  BCol,
  BLink,
  BButton,
  BImg,
  BAvatar,
  BAvatarGroup,
  VBTooltip,
} from "bootstrap-vue";
import { ref } from "@vue/composition-api";
import UserListAddNew from "./UserListAddNew.vue";
import UserListEdit from "./UserListEdit.vue";

export default {
  name: "RolesBoxes",
  components: {
    BCard,
    BCardText,
    BLink,
    BButton,
    BRow,
    BCol,
    BImg,
    BAvatar,
    BAvatarGroup,
    UserListAddNew,
    UserListEdit,
  },
  directives: { "b-tooltip": VBTooltip },
  setup() {
    const isAddNewUserSidebarActive = ref(false);
    const isEditUserSidebarActive = ref(false);

    const roleOptions = [
      { label: "Administrator", item: 5 },
      { label: "Manager", item: 3 },
      { label: "Users", item: 2 },
      { label: "Support", item: 4 },
      { label: "Restricted User", item: 6 },
    ];

    return {
      isAddNewUserSidebarActive,
      isEditUserSidebarActive,
      roleOptions,
    };
  },
};
</script>

<style>
.size {
  height: 300px;
}
.copy:hover {
  color: #7367f0;
  cursor: pointer;
}
.avatars > div {
  justify-content: right;
}
.text {
  margin-top: -10px;
}
</style>
