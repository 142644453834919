<template>
  <b-modal
    id="edit-user-sidebar"
    size="lg"
    :visible="isEditUserSidebarActive"
    header-bg-variant="transparent"
    hide-footer
    center
    @hidden="resetForm"
    @change="(val) => $emit('update:is-edit-user-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <div class="text-center">
        <h1>Edit Role</h1>
        <span>Set role permissions</span>
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- Role Name -->
          <validation-provider
            #default="validationContext"
            name="role name"
            rules="required"
          >
            <b-form-group label="Role Name" label-for="role-name">
              <b-form-input
                id="role-name"
                v-model="userData.fullName"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Enter role name"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <div>
            <h1 class="mb-1 mt-2">Role Permissions</h1>
            <b-row align-v="end">
              <b-col>Administrator Access</b-col>
              <b-col>
                <b-form-group class="core-permission mb-0">
                  <b-form-checkbox
                    id="core-permission"
                    v-model="corePermission"
                    class="mr-50 cursor-pointer"
                  />
                  <label for="core-permission" class="cursor-pointer mb-0"
                    >Select All</label
                  >
                </b-form-group>
              </b-col>
            </b-row>

            <hr class="dropdown-divider mb-1" />

            <b-row align-v="end">
              <b-col sm="6">User Management</b-col>
              <b-col sm="2">
                <b-form-group class="core-permission mb-0">
                  <b-form-checkbox
                    id="core-permission"
                    v-model="corePermission"
                    class="mr-50 cursor-pointer"
                  />
                  <label for="core-permission" class="cursor-pointer mb-0"
                    >Read</label
                  >
                </b-form-group>
              </b-col>
              <b-col sm="2">
                <b-form-group class="core-permission mb-0">
                  <b-form-checkbox
                    id="core-permission"
                    v-model="corePermission"
                    class="mr-50 cursor-pointer"
                  />
                  <label for="core-permission" class="cursor-pointer mb-0"
                    >Write</label
                  >
                </b-form-group>
              </b-col>
              <b-col sm="2">
                <b-form-group class="core-permission mb-0">
                  <b-form-checkbox
                    id="core-permission"
                    v-model="corePermission"
                    class="mr-50 cursor-pointer"
                  />
                  <label for="core-permission" class="cursor-pointer mb-0"
                    >Create</label
                  >
                </b-form-group>
              </b-col>
            </b-row>

            <hr class="dropdown-divider mb-1" />

            <b-row align-v="end">
              <b-col sm="6">Content Management</b-col>
              <b-col sm="2">
                <b-form-group class="core-permission mb-0">
                  <b-form-checkbox
                    id="core-permission"
                    v-model="corePermission"
                    class="mr-50 cursor-pointer"
                  />
                  <label for="core-permission" class="cursor-pointer mb-0"
                    >Read</label
                  >
                </b-form-group>
              </b-col>
              <b-col sm="2">
                <b-form-group class="core-permission mb-0">
                  <b-form-checkbox
                    id="core-permission"
                    v-model="corePermission"
                    class="mr-50 cursor-pointer"
                  />
                  <label for="core-permission" class="cursor-pointer mb-0"
                    >Write</label
                  >
                </b-form-group>
              </b-col>
              <b-col sm="2">
                <b-form-group class="core-permission mb-0">
                  <b-form-checkbox
                    id="core-permission"
                    v-model="corePermission"
                    class="mr-50 cursor-pointer"
                  />
                  <label for="core-permission" class="cursor-pointer mb-0"
                    >Create</label
                  >
                </b-form-group>
              </b-col>
            </b-row>

            <hr class="dropdown-divider mb-1" />

            <b-row align-v="end">
              <b-col sm="6">Disputes Management</b-col>
              <b-col sm="2">
                <b-form-group class="core-permission mb-0">
                  <b-form-checkbox
                    id="core-permission"
                    v-model="corePermission"
                    class="mr-50 cursor-pointer"
                  />
                  <label for="core-permission" class="cursor-pointer mb-0"
                    >Read</label
                  >
                </b-form-group>
              </b-col>
              <b-col sm="2">
                <b-form-group class="core-permission mb-0">
                  <b-form-checkbox
                    id="core-permission"
                    v-model="corePermission"
                    class="mr-50 cursor-pointer"
                  />
                  <label for="core-permission" class="cursor-pointer mb-0"
                    >Write</label
                  >
                </b-form-group>
              </b-col>
              <b-col sm="2">
                <b-form-group class="core-permission mb-0">
                  <b-form-checkbox
                    id="core-permission"
                    v-model="corePermission"
                    class="mr-50 cursor-pointer"
                  />
                  <label for="core-permission" class="cursor-pointer mb-0"
                    >Create</label
                  >
                </b-form-group>
              </b-col>
            </b-row>

            <hr class="dropdown-divider mb-1" />

            <b-row align-v="end">
              <b-col sm="6">Database Management</b-col>
              <b-col sm="2">
                <b-form-group class="core-permission mb-0">
                  <b-form-checkbox
                    id="core-permission"
                    v-model="corePermission"
                    class="mr-50 cursor-pointer"
                  />
                  <label for="core-permission" class="cursor-pointer mb-0"
                    >Read</label
                  >
                </b-form-group>
              </b-col>
              <b-col sm="2">
                <b-form-group class="core-permission mb-0">
                  <b-form-checkbox
                    id="core-permission"
                    v-model="corePermission"
                    class="mr-50 cursor-pointer"
                  />
                  <label for="core-permission" class="cursor-pointer mb-0"
                    >Write</label
                  >
                </b-form-group>
              </b-col>
              <b-col sm="2">
                <b-form-group class="core-permission mb-0">
                  <b-form-checkbox
                    id="core-permission"
                    v-model="corePermission"
                    class="mr-50 cursor-pointer"
                  />
                  <label for="core-permission" class="cursor-pointer mb-0"
                    >Create</label
                  >
                </b-form-group>
              </b-col>
            </b-row>

            <hr class="dropdown-divider mb-1" />

            <b-row align-v="end">
              <b-col sm="6">Financial Management</b-col>
              <b-col sm="2">
                <b-form-group class="core-permission mb-0">
                  <b-form-checkbox
                    id="core-permission"
                    v-model="corePermission"
                    class="mr-50 cursor-pointer"
                  />
                  <label for="core-permission" class="cursor-pointer mb-0"
                    >Read</label
                  >
                </b-form-group>
              </b-col>
              <b-col sm="2">
                <b-form-group class="core-permission mb-0">
                  <b-form-checkbox
                    id="core-permission"
                    v-model="corePermission"
                    class="mr-50 cursor-pointer"
                  />
                  <label for="core-permission" class="cursor-pointer mb-0"
                    >Write</label
                  >
                </b-form-group>
              </b-col>
              <b-col sm="2">
                <b-form-group class="core-permission mb-0">
                  <b-form-checkbox
                    id="core-permission"
                    v-model="corePermission"
                    class="mr-50 cursor-pointer"
                  />
                  <label for="core-permission" class="cursor-pointer mb-0"
                    >Create</label
                  >
                </b-form-group>
              </b-col>
            </b-row>

            <hr class="dropdown-divider mb-1" />

            <b-row align-v="end">
              <b-col sm="6">Reporting</b-col>
              <b-col sm="2">
                <b-form-group class="core-permission mb-0">
                  <b-form-checkbox
                    id="core-permission"
                    v-model="corePermission"
                    class="mr-50 cursor-pointer"
                  />
                  <label for="core-permission" class="cursor-pointer mb-0"
                    >Read</label
                  >
                </b-form-group>
              </b-col>
              <b-col sm="2">
                <b-form-group class="core-permission mb-0">
                  <b-form-checkbox
                    id="core-permission"
                    v-model="corePermission"
                    class="mr-50 cursor-pointer"
                  />
                  <label for="core-permission" class="cursor-pointer mb-0"
                    >Write</label
                  >
                </b-form-group>
              </b-col>
              <b-col sm="2">
                <b-form-group class="core-permission mb-0">
                  <b-form-checkbox
                    id="core-permission"
                    v-model="corePermission"
                    class="mr-50 cursor-pointer"
                  />
                  <label for="core-permission" class="cursor-pointer mb-0"
                    >Create</label
                  >
                </b-form-group>
              </b-col>
            </b-row>

            <hr class="dropdown-divider mb-1" />

            <b-row align-v="end">
              <b-col sm="6">API Control</b-col>
              <b-col sm="2">
                <b-form-group class="core-permission mb-0">
                  <b-form-checkbox
                    id="core-permission"
                    v-model="corePermission"
                    class="mr-50 cursor-pointer"
                  />
                  <label for="core-permission" class="cursor-pointer mb-0"
                    >Read</label
                  >
                </b-form-group>
              </b-col>
              <b-col sm="2">
                <b-form-group class="core-permission mb-0">
                  <b-form-checkbox
                    id="core-permission"
                    v-model="corePermission"
                    class="mr-50 cursor-pointer"
                  />
                  <label for="core-permission" class="cursor-pointer mb-0"
                    >Write</label
                  >
                </b-form-group>
              </b-col>
              <b-col sm="2">
                <b-form-group class="core-permission mb-0">
                  <b-form-checkbox
                    id="core-permission"
                    v-model="corePermission"
                    class="mr-50 cursor-pointer"
                  />
                  <label for="core-permission" class="cursor-pointer mb-0"
                    >Create</label
                  >
                </b-form-group>
              </b-col>
            </b-row>

            <hr class="dropdown-divider mb-1" />

            <b-row align-v="end">
              <b-col sm="6">Repository Management</b-col>
              <b-col sm="2">
                <b-form-group class="core-permission mb-0">
                  <b-form-checkbox
                    id="core-permission"
                    v-model="corePermission"
                    class="mr-50 cursor-pointer"
                  />
                  <label for="core-permission" class="cursor-pointer mb-0"
                    >Read</label
                  >
                </b-form-group>
              </b-col>
              <b-col sm="2">
                <b-form-group class="core-permission mb-0">
                  <b-form-checkbox
                    id="core-permission"
                    v-model="corePermission"
                    class="mr-50 cursor-pointer"
                  />
                  <label for="core-permission" class="cursor-pointer mb-0"
                    >Write</label
                  >
                </b-form-group>
              </b-col>
              <b-col sm="2">
                <b-form-group class="core-permission mb-0">
                  <b-form-checkbox
                    id="core-permission"
                    v-model="corePermission"
                    class="mr-50 cursor-pointer"
                  />
                  <label for="core-permission" class="cursor-pointer mb-0"
                    >Create</label
                  >
                </b-form-group>
              </b-col>
            </b-row>

            <hr class="dropdown-divider mb-1" />

            <b-row align-v="end">
              <b-col sm="6">Payroll</b-col>
              <b-col sm="2">
                <b-form-group class="core-permission mb-0">
                  <b-form-checkbox
                    id="core-permission"
                    v-model="corePermission"
                    class="mr-50 cursor-pointer"
                  />
                  <label for="core-permission" class="cursor-pointer mb-0"
                    >Read</label
                  >
                </b-form-group>
              </b-col>
              <b-col sm="2">
                <b-form-group class="core-permission mb-0">
                  <b-form-checkbox
                    id="core-permission"
                    v-model="corePermission"
                    class="mr-50 cursor-pointer"
                  />
                  <label for="core-permission" class="cursor-pointer mb-0"
                    >Write</label
                  >
                </b-form-group>
              </b-col>
              <b-col sm="2">
                <b-form-group class="core-permission mb-0">
                  <b-form-checkbox
                    id="core-permission"
                    v-model="corePermission"
                    class="mr-50 cursor-pointer"
                  />
                  <label for="core-permission" class="cursor-pointer mb-0"
                    >Create</label
                  >
                </b-form-group>
              </b-col>
            </b-row>

            <hr class="dropdown-divider mb-1" />
          </div>

          <!-- Form Actions -->
          <div class="d-flex mt-3 justify-content-center">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Submit
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Discard
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-modal>
</template>

<script>
import {
  BForm,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BFormInvalidFeedback,
  BButton,
  BModal,
  BRow,
  BCol,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { ref } from "@vue/composition-api";
import { required } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import store from "@/store";

export default {
  components: {
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormInvalidFeedback,
    BButton,
    BRow,
    BCol,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isEditUserSidebarActive",
    event: "update:is-edit-user-sidebar-active",
  },
  props: {
    isEditUserSidebarActive: {
      type: Boolean,
      required: true,
    },
    // roleOptions: {
    //   type: Array,
    //   required: true,
    // },
    // planOptions: {
    //   type: Array,
    //   required: true,
    // },
  },
  data() {
    return {
      required,
      corePermission: false,
      // alphaNum,
      // email,
      // countries,
    };
  },
  setup(props, { emit }) {
    const blankUserData = {
      fullName: "",
      username: "",
      email: "",
      role: null,
      currentPlan: null,
      company: "",
      country: "",
      contact: "",
    };

    const userData = ref(JSON.parse(JSON.stringify(blankUserData)));
    const resetuserData = () => {
      userData.value = JSON.parse(JSON.stringify(blankUserData));
    };

    const onSubmit = () => {
      store.dispatch("app-user/addUser", userData.value).then(() => {
        emit("refetch-data");
        emit("update:is-edit-user-sidebar-active", false);
      });
    };

    // eslint-disable-next-line operator-linebreak
    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetuserData);

    return {
      userData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}

.core-permission > div {
  display: flex;
}
</style>
