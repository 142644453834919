<template>
  <div id="users-roles">
    <roles-cards />
    <users-list />
  </div>
</template>

<script>
import RolesCards from "./roles-list/RolesCards.vue";
import UsersList from "./users-list/UsersList.vue";

export default {
  name: "UsersRoles",
  components: {
    RolesCards,
    UsersList,
  },
};
</script>
